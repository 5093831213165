
import { defineComponent, ref } from 'vue';

import useAuthentication from '@/uses/useAuthentication';
import useView from '@/uses/useView';
import getConfig from '@/plugins/config';

const GRID_URL = getConfig('VUE_APP_GRIDS_BASE_URL');
export default defineComponent({
  setup() {
    console.log(GRID_URL);
    const { username } = useAuthentication();

    const { views } = useView();

    const isSidebarVisible = ref<boolean>(false);

    const isMenuVisible = ref<boolean>(true);

    const toggleSidebar = (): void => {
      isSidebarVisible.value = !isSidebarVisible.value;
    };

    const toggleMenu = (): void => {
      isMenuVisible.value = !isMenuVisible.value;
    };

    return {
      username,
      views,
      isSidebarVisible,
      isMenuVisible,
      toggleSidebar,
      toggleMenu,
    };
  },
  data() {
    return {
      // eslint-disable-next-line eqeqeq
      hasUrlValue: GRID_URL == undefined || GRID_URL == '',
    };
  },
});

